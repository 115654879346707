import React from "react";
import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("../pages/Home"));
const SpamTraining = lazy(() => import("../pages/SpamTraining"));
const SpamReviews = lazy(() => import("../pages/SpamReviews"));
const BasesTraining = lazy(() => import("../pages/BasesTraining"));
const BasesReviews = lazy(() => import("../pages/BasesReviews"));
const ServiceBases = lazy(() => import("../pages/ServiceBases"));
const ServiceSpam = lazy(() => import("../pages/ServiceSpam"));
const MailService = lazy(() => import("../pages/MailService"));
const Contact = lazy(() => import("../pages/Contact"));

export const ROUTE_NAMES = {
  main: "/",
  app: "/megamailer",
  reviews: "/reviews",
  training: "/training",
  servicesBases: "/services-bases",
  servicesSpam: "/services-spam",
  servicesMail: "/services-mail",
  basesReviews: "/bases-reviews",
  basesTraining: "/bases-training",
  spamReviews: "/spam-reviews",
  spamTraining: "/spam-training",
    contact: "/contact",
    asdasdasd: "/asdasdasd"
};

const AppRouter = () => {
  return (
      <>
        <style>{`
      .spinner {
      position: relative;
      width: 22.4px;
      height: 22.4px;
    }

      .spinner::before,
      .spinner::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      animation: spinner-b4c8mmmd 0.5s backwards, spinner-49opz7md 1.25s 0.5s infinite ease;
      border: 5.6px solid #dd1405;
      border-radius: 50%;
      box-shadow: 0 -33.6px 0 -5.6px #dd1405;
      position: absolute;
    }

      .spinner::after {
        animation-delay: 0s, 1.25s;
    }

      @keyframes spinner-b4c8mmmd {
      from {
      box-shadow: 0 0 0 -5.6px #dd1405;
    }
    }

      @keyframes spinner-49opz7md {
      to {
      transform: rotate(360deg);
    }
    }`}
        </style>
        <Suspense
            fallback={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
              <div className='spinner'></div>
            </div>}>
          <Routes>
            <Route path={ROUTE_NAMES.main} element={<Home/>}></Route>
            <Route path={ROUTE_NAMES.spamTraining} element={<BasesTraining/>}></Route>
            <Route path={ROUTE_NAMES.spamReviews} element={<SpamReviews/>}></Route>
            <Route path={ROUTE_NAMES.basesTraining} element={<SpamTraining/>}></Route>
            <Route path={ROUTE_NAMES.basesReviews} element={<BasesReviews/>}></Route>
            <Route path={ROUTE_NAMES.servicesBases} element={<ServiceBases/>}></Route>
            <Route path={ROUTE_NAMES.servicesSpam} element={<ServiceSpam/>}></Route>
            <Route path={ROUTE_NAMES.servicesMail} element={<MailService/>}></Route>
            <Route path={ROUTE_NAMES.contact} element={<Contact/>}></Route>
            <Route path={ROUTE_NAMES.app} element={<></>}></Route>
            <Route path={ROUTE_NAMES.asdasdasd} element={<></>}></Route>
          </Routes>
        </Suspense>
      </>
  );
};

export default AppRouter;
